<template>
  <div id="article-detail-page" class=" bg-pink relative">
    <b-loading :is-full-page="false" :active.sync="pageIsLoading"></b-loading>
    <div
      :style="{ backgroundImage: `url(${articleData.articleImageUrl})` }"
      class="w-screen bg-cover parallax sm:hidden"
    />
    <img
      src="../../assets/icons/back-arrow.svg"
      alt="Back Arrow"
      class="absolute top-0 left-0 w-8 h-8 ml-5 mt-5 sm:hidden"
      @click="$router.push('/articles');">
    <div class="article-data px-10 rounded-t-4xl relative bottom-10 bg-pink sm:pt-60 sm:static sm:pb-24">
      <div class="absolute left-5 -top-20 sm:static sm:flex sm:items-center">
        <img
          :src="articleData.ownerPhotoUrl"
          alt="Article Poster Photo"
          class="w-16 h-16 object-cover rounded-full hidden sm:block mr-4"
        >
        <div>
          <div class="font-montserrat text-3xl text-white font-medium sm:text-black sm:font-semibold">
            {{articleData.companyName}}
          </div>
          <div class="font-roboto font-medium text-lg text-white sm:text-black">
            {{articleDate}}
          </div>
        </div>
      </div>
      <div class="font-montserrat text-black text-3xl font-medium py-10
           sm:text-center sm:font-bold sm:text-4xl sm:pt-20">
        {{articleData.articleName}}
      </div>
      <div v-html="articleData.content" class="font-robot text-base text-black sm:text-lg"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageIsLoading: true,
      articleData: {},
      articleDate: "",
    };
  },
  created() {
    fetch(`https://us-central1-eestec-backend.cloudfunctions.net/app/api/articles/${this.$route.params.articleName}`,
      { method: 'GET' }).then((response) => {
      response.json().then((parsedResponse) => {
        this.articleData = parsedResponse;
        this.articleData.articleImageUrl = this.articleData.articleImageUrl
          ? this.articleData.articleImageUrl : "https://i.imgur.com/wcbCIc3.jpeg.jpg";
        // eslint-disable-next-line dot-notation
        const currentDate = new Date(parsedResponse.createdDate['_seconds'] * 1000);
        this.articleDate = `${currentDate.getDate()}.${currentDate.getMonth()}.${currentDate.getFullYear()}`;
      });
    }).finally(() => {
      this.pageIsLoading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 90vh;
  &::after {
    content: "";
    background: $dark_grey;
    position: absolute;
    inset: 0;
    opacity: 0.7;
    z-index: 0;
  }
}
.article-data {
  box-shadow: 0px -6px 10px -5px rgba(0,0,0,0.75);
  @media screen and (min-width:480px) {
    box-shadow: none;
    max-width: 1080px;
    margin: auto;
  }
}
</style>
